import React, { useEffect, useState } from "react";
import style from "./extrabtns.module.css";
import { connect } from "react-redux";
import BtnsEditModel from "../../Components/btnsEditModel/BtnsEditModel";
import { useNavigate } from "react-router-dom";
import AllExports from "../../AllExportsImports";

const ExtraBtns = ({ extraBtns }) => {
  const [btns, setBtns] = useState([
    extraBtns.btn1,
    extraBtns.btn2,
    extraBtns.btn3,
  ]);
  const [editModal, setEditModal] = useState({
    btnName: "",
    visible: false,
  });
  const [editBtnData, setEditBtnData] = useState({
    visible: false,
    title: "",
    url: "",
    style: {
      color: "",
      bgColor: "",
    },
    icon: {
      name: "",
      type: "",
    },
    headers: {
      key: "",
      value: "",
    },
  });
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.buttonsFlage) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div>
      <div className={style.mainDiv}>
        {editModal.visible ? (
          <BtnsEditModel
            btns={btns}
            setBtns={setBtns}
            editBtnData={editBtnData}
            editModal={editModal}
            setEditBtnData={setEditBtnData}
            setEditModal={setEditModal}
          />
        ) : (
          btns.map((ele, ind) => {
            return (
              <div key={ind} className={style.bigButtons}>
                <div>
                  <p>Title: {ele.title}</p>
                  <p>
                    Url:{" "}
                    <a href={ele.url} title={ele.url}>
                      {ele.url}
                    </a>
                  </p>
                  <div>
                    <p>Headers:</p>
                    {ele.headers !== "" ? (
                      <ol>
                        {Object.entries(ele.headers).map(([key, val]) => {
                          return (
                            <li title={val} key={key}>
                              {key}: {val}
                            </li>
                          );
                        })}
                      </ol>
                    ) : (
                      "No Headers"
                    )}
                  </div>
                  <p>Background Color: {ele.style.bgColor}</p>
                  <p>Color: {ele.style.color}</p>
                  <p>Icon Name: {ele.icon.name}</p>
                  <p>Icon Type: {ele.icon.type}</p>
                  <p>Visibility: {ele.visible.toString()}</p>
                </div>
                <button
                  className={style.btn}
                  onClick={() => {
                    if (ele.headers !== undefined) {
                      setEditBtnData({
                        ...ele,
                        headers:
                          ele.headers !== ""
                            ? {
                                key: Object.keys(ele.headers)[0],
                                value: Object.values(ele.headers)[0],
                              }
                            : {
                                key: "",
                                value: "",
                              },
                      });
                    } else {
                      setEditBtnData(ele);
                    }

                    setEditModal({
                      visible: true,
                      btnName: "btn" + (ind + 1),
                    });
                  }}
                >
                  Edit
                </button>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    extraBtns: state.extraBtns,
  };
};

export default connect(mapStateToProps)(ExtraBtns);
