import React, { useEffect, useState } from "react";
import style from "./users.module.css";
import { database } from "../../../Config/firebase";
import { ref, update } from "firebase/database";
import { Alert } from "rsuite";

const SubscribedDevices = ({ FCMTopics }) => {
  const [topicsCount, setTopicsCount] = useState({
    all: 0,
    deathNews: 0,
    events: 0,
    testing: 0,
  });
  const [deviceInfo, setDeviceInfo] = useState([]);
  const getTopicsCount = () => {
    let count = {
      all: 0,
      deathNews: 0,
      events: 0,
      testing: 0,
    };
    for (const key in FCMTopics) {
      if (Object.hasOwnProperty.call(FCMTopics, key)) {
        const element = FCMTopics[key];
        if (element.all) {
          count.all += 1;
        }
        if (element.deathNews) {
          count.deathNews += 1;
        }
        if (element.events) {
          count.events += 1;
        }
        if (element.testing) {
          count.testing += 1;
        }
      }
    }
    setTopicsCount({ ...count });
  };
  const getDeviceInfo = () => {
    let arr = [];
    for (const key in FCMTopics) {
      const subscriptions = FCMTopics[key];
      if (subscriptions.deviceInfo) {
        arr.push(subscriptions);
      }
    }
    setDeviceInfo(arr);
  };

  useEffect(() => {
    getTopicsCount();
    getDeviceInfo();
  }, [FCMTopics]);

  const allowTesting = (deviceId, permission) => {
    update(ref(database, `topicSubscriptions/${deviceId}/deviceInfo`), {
      allowTesting: permission,
    })
      .then(() => {
        Alert.success(
          `Testing ${permission ? "Enabled" : "Disabled"} Successfully`,
        );
      })
      .catch((error) => {
        Alert.error("An Error Occurred. Check Logs");
        console.log("Updating Error", error);
      });
  };

  return (
    <main className={style.main}>
      <h3>Topic Details</h3>
      <ol>
        <li>All: {topicsCount.all}</li>
        <li>Death News: {topicsCount.deathNews}</li>
        <li>Events: {topicsCount.events}</li>
        <li>Testing: {topicsCount.testing}</li>
        <li>Devices: {deviceInfo.length}</li>
      </ol>
      <h3>Subscribed Devices</h3>
      <table className="dataTable">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>Device ID</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Android Version</th>
            <th>All</th>
            <th>Death News</th>
            <th>Events</th>
            <th>Testing</th>
            <th>Allow Testing</th>
          </tr>
        </thead>
        <tbody>
          {deviceInfo.map((ele, ind) => {
            const device = ele.deviceInfo;
            return (
              <tr key={ind}>
                <td>{ind + 1}</td>
                <td>{device.deviceId}</td>
                <td>{device.brand}</td>
                <td>{device.model}</td>
                <td>{device.androidVersion}</td>
                <td>{ele.all.toString()}</td>
                <td>{ele.deathNews.toString()}</td>
                <td>{ele.events.toString()}</td>
                <td>{ele.testing.toString()}</td>
                <td>
                  <button
                    onClick={() =>
                      allowTesting(device.deviceId, !device.allowTesting)
                    }
                  >
                    {device.allowTesting ? "Disable" : "Enable"}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </main>
  );
};

export default SubscribedDevices;
